/* External dependencies */
import { Anchor, Col, Div, Label, Row, Text, Switch } from 'atomize';
import { navigate } from 'gatsby-plugin-react-intl';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import 'react-calendar/dist/Calendar.css';

/* Local dependencies */
import CalendarIcon from '../../../assets/svg/calendar';
import ClipIcon from '../../../assets/svg/clip';
import formatMessage from '../../../intl/formatMessage';
import { RootState } from '../../../redux/store';
import DangerButton from '../../buttons/DangerButton';
import PrimaryButton from '../../buttons/PrimaryButton';
import { convertFileToBase64 } from '../../common/convertImageToBase64';
import {
  dateNowFormatMessage,
  DateTimeFormatMessage,
} from '../../common/DateTime';
import { isBrowser } from '../../common/helpers';
import CustomSelect from '../../custom-select/CustomSelect';
import FormField from '../../form/FormField';
import {
  inputCurrentWeightStyle,
  inputSuccessWeightStyle,
  labelStyleIndividual,
  rowVehicleStyle,
} from '../../form/FormStyles';
import BackNav from '../../nav/BackNav';
import ErrorPopup from '../../popup/ErrorPopup';
import PrimaryPopup from '../../popup/PrimaryPopup';
import SuccessPopup from '../../popup/SuccessPopup';
import { PageSpinner } from '../../spinner/Spinner';
import {
  PaymentDocumentType,
  PaymentStatus,
  PaymentType,
} from './common/invoice-types';
import {
  cancelPaymentPopup,
  isSuccessPopup,
  makePaymentRequest,
  resetError,
  revokePaymentRequest,
  showConfirmationPopup,
  setPaymentField,
  updateInvoiceFields,
  getInvoice,
} from './redux/actions';
import './style.scss';

export default function GetInvoice() {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [fileName, setFileName] = useState('');

  const {
    applicationNumber,
    cancelPayment,
    declarationMask,
    declarationNumber,
    documentType,
    error,
    file,
    invoice,
    isSuccess,
    isSuccessCancelPayment,
    documentIdError,
    loading,
    payment,
    paymentType,
    paymentTypeError,
    shouldShowConfirmationPopup,
  } = useSelector((state: RootState) => state.invoice);

  const applicationNumberPlaceholder = formatMessage(
    'applicationNumberPlaceholder',
  );
  const declarationNumberPlaceholder = formatMessage(
    'declarationNumberPlaceholder',
  );

  const paymentTypes = Object.keys(PaymentType).map((key) => ({
    label: formatMessage(PaymentType[key]),
    value: PaymentType[key],
  }));

  const id: any = isBrowser && localStorage.getItem('invoiceId');

  useEffect(() => {
    if (!id) {
      navigate('/invoices');
    }
    dispatch(getInvoice(id));
  }, []);

  if (loading) {
    return <PageSpinner />;
  }

  const borderColor = 'search_bg_color';
  const colSize = '5';

  function openConfirmationPopup(e) {
    e.preventDefault();
    switch (invoice.paymentStatus) {
      case PaymentStatus.PAID: {
        dispatch(cancelPaymentPopup(!cancelPayment));
        break;
      }
      case PaymentStatus.UNPAID: {
        dispatch(showConfirmationPopup(!shouldShowConfirmationPopup));
        dispatch(
          setPaymentField({
            id: invoice.id,
            paymentAmount: invoice.paymentAmount,
            paymentCurrency: 'KGZ',
            applicationNumber,
            declarationNumber,
            paymentType,
            documentType,
            file,
          }),
        );
        break;
      }
    }
  }

  function closeConfirmationPopup() {
    dispatch(showConfirmationPopup(!shouldShowConfirmationPopup));
  }

  function onSubmit() {
    dispatch(makePaymentRequest(payment));
  }

  function revokePaymentOnSubmit() {
    dispatch(revokePaymentRequest(invoice.id));
  }

  function closecancelPaymentPopup() {
    dispatch(cancelPaymentPopup(!cancelPayment));
  }

  function onSuccessSubmit() {
    dispatch(isSuccessPopup());
  }

  function onInputChange(e) {
    const { name, value } = e.target;

    dispatch(updateInvoiceFields({ [name]: value }));
  }

  function onSwitch() {
    dispatch(
      updateInvoiceFields({
        documentType:
          documentType === PaymentDocumentType.APPLICATION
            ? PaymentDocumentType.DECLARATION
            : PaymentDocumentType.APPLICATION,
      }),
    );
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const base64 = await convertFileToBase64(file);

      setFileName(
        file.name.length >= 14
          ? file.name.substring(0, 10) + '.pdf'
          : file.name,
      );
      dispatch(updateInvoiceFields({ file: base64 }));
    } else {
      setFileName('');
      dispatch(updateInvoiceFields({ file: '' }));
    }
  };

  const isConsignee = invoice?.consignee && invoice?.consignee !== null;
  const isInvoicePaid =
    invoice?.paymentStatus && invoice?.paymentStatus === 'PAID';
  const application = documentType === PaymentDocumentType.APPLICATION;
  const declaration = documentType === PaymentDocumentType.DECLARATION;
  const isValid = application ? !applicationNumber : !declarationNumber;

  const errorMessage =
    error &&
    (error.code ? <FormattedMessage id={error.code} /> : error.message);

  return (
    <form onSubmit={openConfirmationPopup}>
      <Row>
        <BackNav
          label={
            isConsignee
              ? invoice?.consignee?.name
              : invoice?.vehicle?.driver?.name
          }
          onClick={() => navigate('/invoices')}
        />
      </Row>
      <Row {...rowVehicleStyle} align="baseline" p={{ t: '1rem' }}>
        <FormField
          bg="input_filled_bg"
          colSize={colSize}
          disabled
          label={<FormattedMessage id="arrivalDate" />}
          inputValue={DateTimeFormatMessage(
            isConsignee
              ? invoice?.consignee?.arrivalDate
              : invoice?.vehicle?.arrivalDate,
          )}
          inputType="text"
          inputName="arrivalDate"
          tabIndex="1"
        />
      </Row>
      {isConsignee && (
        <Row
          {...rowVehicleStyle}
          align="baseline"
          border={{ b: '1px solid' }}
          borderColor={borderColor}
          d="flex"
        >
          <FormField
            bg="input_filled_bg"
            colSize={colSize}
            disabled
            label={<FormattedMessage id="cargoWeight" />}
            inputValue={invoice.consignee.cargoWeight}
            inputType="text"
            inputName="cargoWeight"
            tabIndex="1"
          />
        </Row>
      )}

      <Col size={{ xs: '12', sm: '5' }}>
        <Row>
          <Label {...labelStyleIndividual}>
            <FormattedMessage id="individualContract" />
          </Label>
        </Row>
      </Col>
      {invoice?.events &&
        invoice?.events?.map((field, index) => {
          return (
            <Row
              {...rowVehicleStyle}
              align="baseline"
              p={{ t: '1rem' }}
              key={index}
            >
              <FormField
                bg="input_filled_bg"
                disabled
                colSize={colSize}
                label={formatMessage(`${field.eventType}${field.unit}`)}
                inputValue={field.sum}
                inputType="text"
                inputName={field.eventType}
              />
            </Row>
          );
        })}
      <Row
        {...rowVehicleStyle}
        align="baseline"
        p={{ t: '1rem' }}
        border={{ t: '1px solid' }}
        borderColor={borderColor}
        pos="relative"
      >
        <FormField
          colSize={colSize}
          disabled
          label={<FormattedMessage id="paymentDate" />}
          inputName="calendar"
          inputType="text"
          inputValue={dateNowFormatMessage(startDate)}
          required={true}
          tabIndex="3"
          suffixIcon={
            <CalendarIcon
              height={20}
              width={20}
              style={{ position: 'absolute', top: '10px', right: '10px' }}
            />
          }
        />
        {isOpen && (
          <>
            <Col size={{ xs: 12, sm: '5' }}></Col>
            <Col pos="relative">
              <Calendar
                value={startDate}
                className="calendar"
                locale="ru-RU"
                next2Label={null}
                prev2Label={null}
              />
            </Col>
          </>
        )}
      </Row>
      <Row
        {...rowVehicleStyle}
        align="baseline"
        p={{ t: '1rem' }}
        border={{ t: '1px solid' }}
        borderColor={borderColor}
        d={isConsignee ? 'flex' : 'none'}
      >
        <FormField
          bg={declaration ? 'input_bg' : 'button_text_color'}
          colSize={colSize}
          error={declaration ? documentIdError : null}
          label={<FormattedMessage id="declarationNumber" />}
          inputName="declarationNumber"
          inputValue={declaration ? declarationMask : null}
          onChange={onInputChange}
          placeholder={declarationNumberPlaceholder}
          required={!isInvoicePaid && isConsignee ? true : false}
          tabIndex="3"
          disabled={application || isInvoicePaid}
        />
        <Col size={{ xs: '12', sm: '10', lg: '2' }}>
          <Div d={isInvoicePaid ? 'none' : 'flex'} justify="flex-end">
            <Label align="center" m={{ b: '1rem' }} onClick={onSwitch}>
              <Switch checked={application} />
              <FormattedMessage id="application" />
            </Label>
          </Div>
        </Col>
      </Row>
      {application && (
        <Row
          {...rowVehicleStyle}
          align="baseline"
          p={{ t: '1rem' }}
          d={isConsignee ? 'flex' : 'none'}
        >
          <FormField
            colSize={colSize}
            error={application ? documentIdError : null}
            label={<FormattedMessage id="applicationNumber" />}
            inputName="applicationNumber"
            inputType="number"
            inputValue={application ? applicationNumber : null}
            onChange={onInputChange}
            placeholder={applicationNumberPlaceholder}
            required={application}
            tabIndex="4"
            disabled={isInvoicePaid}
          />
        </Row>
      )}
      <Row
        {...rowVehicleStyle}
        align="baseline"
        p={{ t: '1rem' }}
        w={{ xs: '100%', sm: '85%' }}
        d={'flex'}
        pos="relative"
      >
        <CustomSelect
          error={paymentTypeError}
          labelSize={'6'}
          inputName="paymentType"
          items={paymentTypes}
          label={<FormattedMessage id="paymentType" />}
          onChange={onInputChange}
          text={'paymentTypePlaceholder'}
          value={paymentType}
          required={true}
          disabled={isInvoicePaid}
        />
        {isInvoicePaid ? (
          <Anchor
            pos={{ sm: 'absolute' }}
            w="max-content"
            left="101%"
            textColor="black"
            align="center"
            transform="translateY(0.3rem)"
            href={file || undefined}
            target="_blank"
            d={isConsignee ? 'flex' : 'none'}
          >
            <ClipIcon width={24} height={24} />
            {file ? '' : 'No file'}
          </Anchor>
        ) : (
          <Label
            pos={{ sm: 'absolute' }}
            w="max-content"
            left="101%"
            align="center"
            htmlFor="upload-image"
            transform="translateY(0.3rem)"
            d={isConsignee ? 'flex' : 'none'}
          >
            <ClipIcon width={24} height={24} />
            {fileName}
            <input
              type="file"
              id="upload-image"
              style={{ display: 'none' }}
              accept="application/pdf"
              onChange={handleFileChange}
            />
          </Label>
        )}
      </Row>
      {!isInvoicePaid && (
        <Row {...rowVehicleStyle} align="baseline" p={{ t: '1rem' }} maxW="85%">
          <FormField
            disabled
            inputStyle={inputCurrentWeightStyle}
            label={<FormattedMessage id="totalSum" />}
            inputName="sum"
            inputType="text"
            inputValue={invoice?.paymentAmount?.toLocaleString()}
            tabIndex="5"
          />
        </Row>
      )}
      {isInvoicePaid && (
        <Row {...rowVehicleStyle} align="baseline" p={{ t: '1rem' }} maxW="85%">
          <FormField
            disabled
            inputStyle={inputSuccessWeightStyle}
            label={<FormattedMessage id="totalSum" />}
            inputName="sum"
            inputType="text"
            inputValue={invoice.paymentAmount.toLocaleString()}
            tabIndex="5"
          />
        </Row>
      )}
      {!isInvoicePaid && (
        <Div w="83%">
          <PrimaryButton
            icon="Checked"
            size="lg"
            loading={loading}
            disabled={isConsignee ? isValid : false}
          >
            <FormattedMessage id="acceptPayment" />
          </PrimaryButton>
        </Div>
      )}
      {isInvoicePaid && (
        <Div w="83%">
          <DangerButton icon="Remove" size="lg" loading={loading}>
            <FormattedMessage id="revokePayment" />
          </DangerButton>
        </Div>
      )}
      {cancelPayment && (
        <ErrorPopup
          title={<FormattedMessage id="cancelPayment" />}
          canselText={<FormattedMessage id="doNotCancel" />}
          submitText={<FormattedMessage id="revokeButton" />}
          canselSubmit={closecancelPaymentPopup}
          onSubmit={revokePaymentOnSubmit}
        >
          <Row>
            <Col>
              <Text>
                {isConsignee
                  ? invoice?.consignee?.name
                  : invoice?.vehicle?.driver?.name}
              </Text>
            </Col>
          </Row>
        </ErrorPopup>
      )}

      {shouldShowConfirmationPopup && !error && (
        <PrimaryPopup
          btnText={<FormattedMessage id="accept" />}
          cancelBtnText={<FormattedMessage id="cancel" />}
          loading={loading}
          onClose={closeConfirmationPopup}
          onClick={onSubmit}
          title={<FormattedMessage id="acceptPaymentQuestion" />}
        >
          <Row>
            <Col>
              <Text>
                {<FormattedMessage id="totalSum" />} {`-`}{' '}
                {invoice.paymentAmount}
              </Text>
            </Col>
          </Row>
        </PrimaryPopup>
      )}

      {isSuccess && (
        <SuccessPopup
          onSubmit={onSuccessSubmit}
          submitText={<FormattedMessage id="close" />}
          title={<FormattedMessage id="paymentAccepted" />}
        >
          <Div>
            <Text>
              {<FormattedMessage id="totalSum" />}
              {` -  ${invoice?.paymentAmount}`}
            </Text>
          </Div>
        </SuccessPopup>
      )}

      {isSuccessCancelPayment && (
        <SuccessPopup
          onSubmit={onSuccessSubmit}
          submitText={<FormattedMessage id="close" />}
          title={<FormattedMessage id="paymentCanceled" />}
        >
          <Div>
            <Text>{<FormattedMessage id="backToUnpaid" />}</Text>
          </Div>
        </SuccessPopup>
      )}

      {error && shouldShowConfirmationPopup && (
        <ErrorPopup
          onSubmit={() => dispatch(resetError())}
          submitText={<FormattedMessage id="close" />}
          title={<FormattedMessage id="errorOccurred" />}
        >
          {errorMessage}
        </ErrorPopup>
      )}
    </form>
  );
}
