/* External dependencies */
import { Col, Container, Row } from 'atomize';
import React from 'react';

/* Local dependencies */
import GetInvoice from '../../../components/invoices/invoice';
import Layout from '../../../components/layout';

export default function Contract() {
  return (
    <Layout>
      <Container>
        <Row>
          <Col size={{ xs: '12' }} maxW="900px" w="100%">
            <GetInvoice />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
