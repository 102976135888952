/* External dependencies */
import { Col, Div, Dropdown, Label, Text } from 'atomize';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { UIException } from '../../redux/exceptions';
import { getInputPlaceholder } from '../common/getInputPlaceholder';
import { Alignment, LabelStyle } from '../common/inputLabelType';
import SelectItems from '../common/SelectItems';
import FormField from '../form/FormField';
import {
  labelStyle,
  labelSecondaryStyle,
  defaultInputStyle,
  errorMessageStyle,
} from '../form/FormStyles';

export interface SelectProps {
  error?: UIException;
  items: any;
  inputName: string;
  label: React.ReactChild;
  labelAlignment?: Alignment; // default HORIZONTAL
  labelDropdownStyle?: LabelStyle; // default PRIMARY
  labelSize?: string; // default none.
  onChange?: Function;
  required?: boolean;
  text?: string;
  value: string;
  disabled?: boolean;
}

export default function CustomSelect(props: SelectProps) {
  const {
    error,
    items,
    label,
    labelAlignment = Alignment.HORIZONTAL,
    labelDropdownStyle = LabelStyle.PRIMARY,
    labelSize,
    onChange,
    inputName,
    required,
    text,
    value,
    disabled,
  } = props;
  const ref = useRef();

  const [{ matchedItems, searchQuery, showDropdownList }, setSearchState] =
    useState({
      matchedItems: items,
      searchQuery: value,
      showDropdownList: false,
    });

  function onSelectedChange(selectedValue) {
    setSearchState({
      matchedItems: matchedItems,
      searchQuery: selectedValue.label,
      // First close the dropdown, then notify of changes.
      showDropdownList: false,
    });

    onChange({
      target: {
        name: `${inputName}`,
        value: selectedValue.value,
      },
    });
  }

  function handleSearch(event) {
    const { value } = event.target;
    const searchValue = value.toLowerCase().trim();

    const matches = items.filter(({ label }) => {
      return label.toLowerCase().search(searchValue) !== -1;
    });

    setSearchState({
      matchedItems: searchValue ? matches : items,
      searchQuery: value,
      showDropdownList: true,
    });
  }

  function openDropdown() {
    setSearchState({
      matchedItems,
      searchQuery,
      showDropdownList: !showDropdownList,
    });
  }

  const customLabelStyle =
    labelDropdownStyle == LabelStyle.PRIMARY ? labelStyle : labelSecondaryStyle;

  const inpuStyle = {
    bg: 'transparent',
    border: 'none',
    p: '1rem 0 0',
  };

  return (
    <>
      {labelAlignment === Alignment.HORIZONTAL && (
        <Col size={{ xs: '12', sm: labelSize }}>
          <Label {...customLabelStyle}>{label}</Label>
        </Col>
      )}
      <Col>
        {labelAlignment === Alignment.VERTICAL && (
          <Label {...customLabelStyle}>{label}</Label>
        )}
        <Div m={{ b: '1rem' }}>
          <Dropdown
            {...defaultInputStyle}
            bg="input_bg"
            style={{ paddingLeft: '8px' }}
            size={labelSize || ''}
            isOpen={showDropdownList}
            value={value}
            onClick={!disabled && openDropdown}
            menu={
              <SelectItems
                items={matchedItems}
                onClick={onSelectedChange}
                reference={ref}
              />
            }
            required={required}
          >
            {Object.keys(items).length > 10 ? (
              <FormField
                autoComplete="off"
                inputValue={searchQuery}
                inputType="text"
                inputName={inputName}
                placeholder={getInputPlaceholder(inputName)}
                onChange={handleSearch}
                inputStyle={inpuStyle}
                focusBg="transparent"
              />
            ) : (
              <FormattedMessage id={value || text} />
            )}
          </Dropdown>
          {error && (
            <Text textSize="caption" {...errorMessageStyle}>
              {formatMessage(error.code)}
            </Text>
          )}
        </Div>
      </Col>
    </>
  );
}
